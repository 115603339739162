import components from './components'

const pageQuery = `
    query Page($slug: String!) {
        nodeByUri(uri: $slug) {
            ... on Page {
                title
                id
                categories {
                    contentTheme
                }
                seo {
                    title
                    metaDesc
                    metaKeywords
                    metaRobotsNofollow
                    metaRobotsNoindex
                    canonical
                    opengraphDescription
                    opengraphTitle
                    twitterTitle
                    twitterDescription
                    twitterImage {
                        sourceUrl(size: LARGE)
                    }
                    opengraphImage {
                        sourceUrl(size: LARGE)
                    }
                }
                components {
                    ${components('Page')}
                }
                pageOptions {
                    headerStyle
                }
            }
        }
    }
`;

export default pageQuery;
